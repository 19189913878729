import { memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Img from '@UI/Img';
import Text from '@Promo/elems/Text';
import Promocode from '@Promo/elems/PromocodeNew';
// eslint-disable-next-line @divlab/css-modules/no-unused-class
import styles from './CategoryBannerTemplate.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { CategoryBannerData } from '@Promo/typings';

export interface CategoryTemplateProps extends HTMLAttributes<HTMLDivElement> {
  banner: CategoryBannerData;
  title?: string;
}

const CategoryBannerTemplate: FC<CategoryTemplateProps> = (props) => {
  const { banner, title, ...restProps } = props;
  const { isMobile } = useMedias();
  const resources = banner.resources.default;
  const image = isMobile ? resources.mobileImage : resources.desktopImage;
  const text = banner.text;

  const stopLink = useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <div
      style={{ backgroundColor: banner.color }}
      className={cn(styles.banner, {
        [styles.inversed]: banner.inversed,
        [styles.withImage]: image,
      })}
      {...restProps}
    >
      {image && <Img className={cn(styles.image)} src={image} loading='eager' />}

      <div className={styles.container}>
        {text?.period && (
          <Text className={styles.period} desktop={text.period} isMobile={isMobile} />
        )}

        {title ? (
          <Text className={styles.title} desktop={title} isMobile={isMobile} isH1 />
        ) : (
          text?.title && (
            <Text
              className={styles.title}
              desktop={text.title}
              mobile={text.titleMobile}
              isMobile={isMobile}
              isH1
            />
          )
        )}

        {(text?.subtitle || text?.promocodeCopy) && (
          <div className={styles.promocodeWrapper}>
            {text?.promocodeCopy?.position === 'start' && (
              <Promocode
                className={styles.promocodeBefore}
                text={text.promocodeCopy.code}
                view={banner.inversed ? 'dark' : 'light'}
                onClick={stopLink}
              />
            )}
            {text?.subtitle && (
              <Text
                className={styles.subtitle}
                desktop={text.subtitle}
                mobile={text.subtitleMobile}
                isMobile={isMobile}
              />
            )}
            {text?.promocodeCopy?.position === 'end' && (
              <Promocode
                className={styles.promocodeAfter}
                text={text.promocodeCopy.code}
                view={banner.inversed ? 'dark' : 'light'}
                onClick={stopLink}
              />
            )}
          </div>
        )}

        {text?.condition && (
          <Text
            className={styles.condition}
            desktop={text.condition}
            mobile={text.conditionMobile}
            isMobile={isMobile}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CategoryBannerTemplate);
